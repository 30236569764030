"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.certificateInvalidationReasons = exports.PartnerService = void 0;
exports.parsePartner = parsePartner;
var _moment = _interopRequireDefault(require("moment"));
var _components = require("../components");
var _environment = require("../environment");
var _models = require("../models");
var _cyclabilityZone = require("./cyclability-zone");
var _employee = require("./employee");
var _geocoder = require("./geocoder");
var _http = require("./http");
var _user = require("./user");
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
class PartnerService {
  static async getPartners(_ref) {
    let {
      isAdmin,
      page,
      pageSize,
      query,
      templates,
      search,
      contractTemplateCodes,
      contractTemplateStates
    } = _ref;
    try {
      if (!_user.UserService.currentUser?.isGeovelo) throw new Error('user not allowed');
      const queryParams = [{
        key: 'page',
        value: page
      }];
      if (pageSize) queryParams.push({
        key: 'page_size',
        value: pageSize
      });
      if (query) queryParams.push({
        key: 'query',
        value: query
      });
      if (search) queryParams.push({
        key: 'search',
        value: search
      });
      if (contractTemplateCodes) queryParams.push({
        key: 'contract_template_codes',
        value: contractTemplateCodes.join(',')
      });
      if (contractTemplateStates) queryParams.push({
        key: 'state[]',
        value: contractTemplateStates.join(',')
      });
      const {
        results,
        count,
        next
      } = await _http.HttpService.get('v3', `/partners`, queryParams);
      return {
        count,
        next,
        partners: results.reduce((res, props) => {
          if (props.contracts?.length === 1 && props.contracts[0].contract_template === templates?.find(_ref2 => {
            let {
              code
            } = _ref2;
            return code === 'cvtc';
          })?.id) return res;
          const partner = parsePartner({
            partner: props
          }, isAdmin);
          if (partner) res.push(partner);
          return res;
        }, [])
      };
    } catch (err) {
      console.error('[PartnerService][getPartners]', err);
      throw err;
    }
  }
  static async getPartner(_ref3) {
    let {
      isAdmin,
      partner: {
        id,
        code,
        query
      }
    } = _ref3;
    try {
      const queryParams = [];
      if (query) queryParams.push({
        key: 'query',
        value: query
      });
      const data = await _http.HttpService.get('v3', `/partners/${id}`, queryParams, [], null, {
        partner: code
      });
      const partner = parsePartner({
        is_admin_partner: isAdmin,
        partner: data
      });
      if (!partner) throw new Error('Partner not found');
      return partner;
    } catch (err) {
      console.error('[PartnerService][getPartner]', err);
      throw err;
    }
  }
  static async addPartner(_ref4) {
    let {
      title,
      administrativeLevel,
      areaId,
      geoagglo,
      geogroup,
      cyclabilityZoneId,
      icon,
      contracts,
      contactEmail,
      whiteListedDomains,
      companyMaxSize,
      siren,
      siret
    } = _ref4;
    try {
      if (!_user.UserService.currentUser) throw new Error('user not connected');
      const props = {
        title,
        geo_agglo: geoagglo?.id || null,
        geo_group: geogroup?.id || null,
        cyclability_zone: cyclabilityZoneId || null,
        area: areaId || geogroup?.areaId || null,
        contact_email: contactEmail || null,
        contracts: (contracts || []).map(_ref5 => {
          let {
            startDate,
            endDate,
            apiUsageLimit,
            contractTemplateId
          } = _ref5;
          return {
            title,
            start_datetime: startDate,
            end_datetime: endDate,
            api_usage_limit: apiUsageLimit,
            contract_template: contractTemplateId
          };
        })
      };
      if (administrativeLevel && administrativeLevel !== 'world') {
        props.administrative_level = _cyclabilityZone.backendAdministrativeLevels[administrativeLevel];
      }
      if (whiteListedDomains) props.whitelisted_domains = whiteListedDomains;
      if (companyMaxSize) props.company_workforce_count = companyMaxSize;
      if (siren) props.siren_number = siren;
      if (siret) props.siret_number = siret;
      let body;
      if (icon) {
        body = new FormData();
        body.append('icon', icon);
        body.append('data', JSON.stringify(props));
      } else {
        body = JSON.stringify(props);
      }
      const data = await _http.HttpService.post('v3', `/partners`, [], [], body, {
        expectedStatus: 201
      });
      const partner = parsePartner({
        is_admin_partner: true,
        partner: data
      });
      if (!partner) throw new Error('invalid partner');
      return partner;
    } catch (err) {
      console.error('[PartnerService][addPartner]', err);
      throw err;
    }
  }
  static async editPartner(partner, _ref6, isAdmin) {
    let {
      title,
      contactEmail,
      icon,
      whiteListedDomains,
      companyMaxSize,
      administrativeLevel,
      areaId,
      geoagglo,
      geogroup,
      cyclabilityZoneId,
      creationReasons
    } = _ref6;
    try {
      if (!_user.UserService.currentUser) throw new Error('user not connected');
      const props = {};
      if (title !== undefined) props.title = title;
      if (contactEmail !== undefined) props.contact_email = contactEmail;
      if (whiteListedDomains) props.whitelisted_domains = whiteListedDomains;
      if (companyMaxSize) props.company_workforce_count = companyMaxSize;
      if (icon === null) props.icon = null;
      if (geoagglo !== undefined) props.geo_agglo = geoagglo?.id || null;
      if (geogroup !== undefined) props.geo_group = geogroup?.id || null;
      if (areaId !== undefined) props.area = areaId;
      if (cyclabilityZoneId !== undefined) props.cyclability_zone = cyclabilityZoneId;
      if (administrativeLevel !== undefined && administrativeLevel !== 'world') {
        props.administrative_level = administrativeLevel === null ? null : _cyclabilityZone.backendAdministrativeLevels[administrativeLevel];
      }
      if (creationReasons) props.creation_reason = creationReasons;
      let body;
      if (icon) {
        body = new FormData();
        body.append('icon', icon);
        body.append('data', JSON.stringify(props));
      } else {
        body = JSON.stringify(props);
      }
      const data = await _http.HttpService.patch('v3', `/partners/${partner.id}`, [], [], body, {
        partner: partner.code,
        expectedStatus: 200
      });
      const updatedPartner = parsePartner({
        partner: data
      }, isAdmin);
      if (!updatedPartner) throw new Error('');
      return updatedPartner;
    } catch (err) {
      console.error('[PartnerService][editPartner]', err);
      throw err;
    }
  }
  static async removePartner(partner) {
    try {
      await _http.HttpService.delete('v3', `/partners/${partner.id}`, [], [], null, partner.code ? {
        partner: partner.code
      } : undefined);
      return true;
    } catch (err) {
      console.error('[PartnerService][removePartner]', err);
      throw err;
    }
  }
  static async getLayers() {
    try {
      const data = await _http.HttpService.get('v2', '/partners/layers');
      return data.reduce((res, _data) => {
        const layer = parsePartnerLayer(_data);
        if (layer) res.push(layer);
        return res;
      }, []);
    } catch (err) {
      console.error('[PartnerService][getLayers]', err);
      throw err;
    }
  }
  static async getPartnerUsers(id, _ref7) {
    let {
      page,
      rowsPerPage
    } = _ref7;
    try {
      const {
        count,
        next,
        previous,
        results
      } = await _http.HttpService.get('v3', `/partners/${id}/users`, [{
        key: 'ordering',
        value: '-id'
      }, {
        key: 'page',
        value: page
      }, {
        key: 'page_size',
        value: rowsPerPage
      }]);
      const users = results.map(parsePartnerUser);
      return {
        count,
        next,
        previous,
        users
      };
    } catch (err) {
      console.error('[PartnerService][getPartnerUsers]', err);
      throw err;
    }
  }
  static async removePartnerUser(partner, userId) {
    try {
      await _http.HttpService.delete('v3', `/partners/${partner.id}/users/${userId}`, [], [], null, {
        partner: partner.code
      });
      return true;
    } catch (err) {
      console.error('[PartnerService][removePartnerUser]', err);
      throw err;
    }
  }
  static async addPartnerUser(partner, userId, is_admin) {
    try {
      const data = await _http.HttpService.post('v3', `/partners/${partner.id}/users`, [], [], JSON.stringify({
        user: userId,
        is_admin_partner: !!is_admin
      }), {
        partner: partner.code
      });
      return data.user;
    } catch (err) {
      console.error('[PartnerService][addPartnerUser]', err);
      throw err;
    }
  }
  static async updatePartnerUser(partner, userId, is_admin) {
    const {
      id: partnerId,
      code
    } = partner;
    try {
      const data = await _http.HttpService.patch('v3', `/partners/${partnerId}/users/${userId}`, [], [], JSON.stringify({
        user: userId,
        is_admin_partner: is_admin
      }), {
        partner: code,
        expectedStatus: 200
      });
      return data.user;
    } catch (err) {
      if (typeof err === 'object' && err && 'status' in err && err.status === 404) {
        return PartnerService.addPartnerUser(partner, userId, is_admin);
      } else {
        console.error('[PartnerService][updatePartnerUser]', err);
        throw err;
      }
    }
  }
  static async getContractTemplates() {
    let {
      query
    } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    try {
      const params = [];
      if (query) params.push({
        key: 'query',
        value: query
      });
      const res = await _http.HttpService.get('v3', `/partner_contract_templates`, params);
      return res.map(parsePartnerContractTemplate).sort((a, b) => a.title?.localeCompare(b.title));
    } catch (err) {
      console.error('[PartnerService][getContractTemplates]', err);
      throw err;
    }
  }
  static sortContracts(a, b) {
    if (a.endDateTime && b.endDateTime) return a.endDateTime.isSame(b.endDateTime) ? a.title.localeCompare(b.title) : a.endDateTime.isBefore(b.endDateTime) ? 1 : -1;
    if (a.endDateTime) return 1;
    if (b.endDateTime) return -1;
    return a.title.localeCompare(b.title);
  }
  static async getContracts(partnerId) {
    let {
      query
    } = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    try {
      const queryParams = [];
      if (query) queryParams.push({
        key: 'query',
        value: query
      });
      const res = await _http.HttpService.get('v3', `/partners/${partnerId}/contracts`, queryParams);
      return res.map(parsePartnerContract).sort(this.sortContracts);
    } catch (err) {
      console.error('[PartnerService][getContracts]', err);
      throw err;
    }
  }
  static async addContract(_ref8) {
    let {
      partner,
      title,
      startDate,
      endDate,
      apiUsageLimit,
      contractTemplateId,
      companyMaxSize
    } = _ref8;
    try {
      const params = {
        title,
        start_datetime: startDate,
        end_datetime: endDate,
        api_usage_limit: apiUsageLimit,
        contract_template: contractTemplateId
      };
      if (companyMaxSize !== undefined) params.company_workforce_count_limit = companyMaxSize;
      const res = await _http.HttpService.post('v3', `/partners/${partner.id}/contracts`, [], [], JSON.stringify(params), {
        partner: partner.code
      });
      return parsePartnerContract(res);
    } catch (err) {
      console.error('[PartnerService][addContract]', err);
      throw err;
    }
  }
  static async updateContract(_ref9) {
    let {
      partner,
      contractId,
      title,
      startDate,
      endDate,
      apiUsageLimit,
      contractTemplateId,
      companyMaxSize
    } = _ref9;
    try {
      const params = {
        title,
        contract_template: contractTemplateId
      };
      if (startDate) params.start_datetime = startDate;
      if (endDate) params.end_datetime = endDate;
      if (apiUsageLimit !== undefined) params.api_usage_limit = apiUsageLimit;
      if (companyMaxSize !== undefined) params.company_workforce_count_limit = companyMaxSize;
      const res = await _http.HttpService.patch('v3', `/contracts/${contractId}`, [], [], JSON.stringify(params), {
        partner: partner.code
      });
      return parsePartnerContract(res);
    } catch (err) {
      console.error('[PartnerService][updateContract]', err);
      throw err;
    }
  }
  static async deleteContract(contractId, _ref10) {
    let {
      partner
    } = _ref10;
    try {
      await _http.HttpService.delete('v3', `/contracts/${contractId}`, [], [], null, {
        partner: partner.code
      });
      return true;
    } catch (err) {
      console.error('[PartnerService][deleteContract]', err);
      throw err;
    }
  }
  static async getFlowsStats(partnerId, _ref11) {
    let {
      period
    } = _ref11;
    try {
      const stats = await _http.HttpService.get('v1', `/stats/partner/${partnerId}/flow_stats`, [{
        key: 'startDate',
        value: period.from.format('YYYY-MM-DD')
      }, {
        key: 'endDate',
        value: period.from.clone().add(1, 'month').format('YYYY-MM-DD')
      }], [], null, {
        expectedStatus: 200
      });
      return parsePartnerFlowsStats(stats);
    } catch (err) {
      if (typeof err === 'object' && err && 'status' in err && err.status === 404) return null;
      console.error('[PartnerService][getFlowsStats]', err);
      throw err;
    }
  }
  static async getH3Frequencies(_ref12, _ref13) {
    let {
      id: partnerId,
      code: partner
    } = _ref12;
    let {
      period
    } = _ref13;
    try {
      const results = await _http.HttpService.get('v1', `/stats/partner/${partnerId}/h3_frequencies`, [{
        key: 'startDate',
        value: period.from.format('YYYY-MM-DD')
      }, {
        key: 'endDate',
        value: period.to.format('YYYY-MM-DD')
      }], [], null, {
        partner
      });
      return results.map(_ref14 => {
        let {
          h3_index: h3Index,
          frequency
        } = _ref14;
        return {
          h3Index,
          frequency
        };
      });
    } catch (err) {
      console.error('[PartnerService][getH3Frequencies]', err);
      throw err;
    }
  }
  static async initializeCompany(_ref15) {
    let {
      id,
      code
    } = _ref15;
    try {
      const data = await _http.HttpService.get('v1', `/partners/${id}/initialize_enterprise`, [], [], null, {
        partner: code
      });
      const partner = parsePartner({
        partner: data
      });
      if (!partner) throw new Error('partner not found');
      return partner;
    } catch (err) {
      console.error('[PartnerService][initializeCompany]', err);
      throw err;
    }
  }
  static async getEmployees(partner, _ref16) {
    let {
      onlyAdmins,
      roles,
      onboardingStatus,
      linkStatus,
      groupsIds,
      search,
      statuses,
      onlyFMDBeneficiaries,
      ordering,
      page,
      pageSize,
      query
    } = _ref16;
    try {
      const params = [{
        key: 'ordering',
        value: ordering
      }, {
        key: 'page',
        value: page
      }, {
        key: 'page_size',
        value: pageSize
      }, {
        key: 'query',
        value: query
      }, {
        key: 'only_admins',
        value: Boolean(onlyAdmins)
      }];
      if (onlyAdmins !== undefined) {
        params.push({
          key: 'only_admins',
          value: onlyAdmins
        });
      }
      if (roles !== undefined) {
        params.push(...roles.map(key => ({
          key: 'role[]',
          value: key
        })));
      }
      if (onboardingStatus !== undefined) {
        params.push({
          key: 'filter_employees_with_completed_onboarding',
          value: onboardingStatus === 'completed' ? true : false
        });
      }
      if (linkStatus === 'linked') params.push({
        key: 'filter_employees_link_with_a_user',
        value: true
      });
      if (groupsIds) {
        params.push(...groupsIds.map(id => ({
          key: 'group_ids[]',
          value: id
        })));
      }
      if (search) params.push({
        key: 'search',
        value: search
      });
      if (statuses && statuses.length > 0) {
        const statusesMap = {
          joined: 'EMPLOYEE_JOIN',
          left: 'EMPLOYEE_QUIT',
          waitingForApproval: 'EMPLOYEE_WAITING_FOR_APPROVAL',
          waitingUserToJoin: 'WAITING_USER_TO_JOIN'
        };
        statuses.forEach(key => params.push({
          key: 'join_status[]',
          value: statusesMap[key]
        }));
      }
      if (onlyFMDBeneficiaries !== undefined) {
        params.push({
          key: 'only_employee_can_declare_fmd',
          value: onlyFMDBeneficiaries
        });
      }
      const {
        count,
        next,
        results
      } = await _http.HttpService.get('v1', `/partners/${partner.id}/employees`, params, [], null, {
        partner: partner.code
      });
      return {
        count,
        hasNext: Boolean(next),
        employees: results.map(_employee.parseEmployee)
      };
    } catch (err) {
      console.error('[PartnerService][getEmployees]', err);
      throw err;
    }
  }
  static async importEmployees(partner, _ref17) {
    let {
      csv
    } = _ref17;
    try {
      const formData = new FormData();
      formData.append('csv', csv);
      formData.append('delimiter', ',');
      const res = await _http.HttpService.post('v1', `/partners/${partner.id}/import_employees`, [], [], formData, {
        partner: partner.code
      });
      return res.length;
    } catch (err) {
      console.error('[PartnerService][importEmployees]', err);
      throw err;
    }
  }
  static async inviteEmployeesAgain(partner, _ref18) {
    let {
      employees
    } = _ref18;
    try {
      await _http.HttpService.post('v1', `/partners/${partner.id}/re_invite_employees`, [], [], JSON.stringify({
        employee_ids: employees.map(_ref19 => {
          let {
            id
          } = _ref19;
          return id;
        })
      }), {
        partner: partner.code
      });
      return true;
    } catch (err) {
      console.error('[PartnerService][inviteEmployeesAgain]', err);
      throw err;
    }
  }
  static async acceptEmployees(_ref20) {
    let {
      partner,
      geogroup,
      employees
    } = _ref20;
    try {
      await _http.HttpService.post('v2', `/geogroups/${geogroup.id}/join_approvals`, [], [], JSON.stringify({
        employee_ids: employees.map(_ref21 => {
          let {
            id
          } = _ref21;
          return id;
        })
      }), {
        partner: partner.code
      });
      return true;
    } catch (err) {
      console.error('[PartnerService][acceptEmployees]', err);
      throw err;
    }
  }
  static async rejectEmployees(_ref22) {
    let {
      partner,
      geogroup,
      employees
    } = _ref22;
    try {
      await _http.HttpService.post('v2', `/geogroups/${geogroup.id}/join_disapprovals`, [], [], JSON.stringify({
        employee_ids: employees.map(_ref23 => {
          let {
            id
          } = _ref23;
          return id;
        })
      }), {
        partner: partner.code
      });
      return true;
    } catch (err) {
      console.error('[PartnerService][acceptEmployees]', err);
      throw err;
    }
  }
  static async getCertificates(_ref24) {
    let {
      partner,
      page,
      pageSize,
      startDate,
      endDate,
      employeeIds,
      onlyValid,
      onlyBeneficiaries,
      search,
      ordering,
      query
    } = _ref24;
    try {
      const params = [{
        key: 'page',
        value: page
      }, {
        key: 'page_size',
        value: pageSize
      }, {
        key: 'period',
        value: 'custom'
      }, {
        key: 'date_start',
        value: startDate.format('YYYY-MM-DD')
      }, {
        key: 'date_end',
        value: endDate.format('YYYY-MM-DD')
      }, {
        key: 'exclude_null_amount_to_pay',
        value: false
      }];
      if (employeeIds && employeeIds.length > 0) params.push({
        key: 'employee_id[]',
        value: employeeIds.join(',')
      });
      if (onlyValid !== undefined) params.push({
        key: 'valid_attestation',
        value: onlyValid
      }, {
        key: 'exclude_temporary_attestation',
        value: onlyValid
      });
      if (onlyBeneficiaries !== undefined) params.push({
        key: 'can_declare_fmd',
        value: onlyBeneficiaries
      });
      if (search) params.push({
        key: 'search',
        value: search
      });
      if (ordering) params.push({
        key: 'ordering',
        value: ordering
      });
      if (query) params.push({
        key: 'query',
        value: query
      });
      const {
        count,
        results
      } = await _http.HttpService.get('v2', `/partners/${partner.id}/fmd_attestations`, params, [], null, {
        partner: partner.code
      });
      return {
        count,
        items: results.map(_ref25 => {
          let {
            fmd_attestations,
            ...data
          } = _ref25;
          return {
            employee: (0, _employee.parseEmployee)(data),
            certificates: fmd_attestations?.map(_employee.parseCertificate) || []
          };
        })
      };
    } catch (err) {
      console.error('[PartnerService][getCertificates]', err);
      throw err;
    }
  }
  static async invalidateCertificates(_ref26) {
    let {
      partner,
      certificatesIds: attestation_ids,
      reason,
      description
    } = _ref26;
    try {
      await _http.HttpService.post('v1', `/partners/${partner.id}/invalidate_fmd_attestations`, [], [], JSON.stringify({
        attestation_ids,
        reason: backendCertificateInvalidationReasonsMap[reason],
        ...(description !== null ? {
          description
        } : {})
      }), {
        partner: partner.code
      });
      return true;
    } catch (err) {
      console.error('[PartnerService][getHomeWorkDistances]', err);
      throw err;
    }
  }
  static async getFMDStats(_ref27) {
    let {
      partner,
      startDate,
      endDate,
      disableTemporary
    } = _ref27;
    try {
      const queryParams = [{
        key: 'period',
        value: 'custom'
      }, {
        key: 'date_start',
        value: startDate.format('YYYY-MM-DD')
      }, {
        key: 'date_end',
        value: endDate.format('YYYY-MM-DD')
      }];
      if (disableTemporary) {
        queryParams.push({
          key: 'temporary_attestation',
          value: false
        }, {
          key: 'valid_attestation',
          value: true
        });
      }
      const {
        amount_to_pay: allowance,
        count_days_total: daysCount,
        sum_distance_total: totalDistance,
        count_home_to_work_journeys: homeToWorkCount,
        count_work_to_home_journeys: workToHomeCount
      } = await _http.HttpService.get('v1', `/partners/${partner.id}/fmd_attestations_stats`, queryParams, [], null, {
        partner: partner.code
      });
      return {
        allowance: allowance || 0,
        daysCount: daysCount || 0,
        totalDistance: totalDistance || 0,
        homeToWorkCount: homeToWorkCount || 0,
        workToHomeCount: workToHomeCount || 0
      };
    } catch (err) {
      console.error('[PartnerService][getFMDStats]', err);
      throw err;
    }
  }
  static async getHomeWorkDistances(_ref28) {
    let {
      partner,
      geogroupId
    } = _ref28;
    try {
      const params = [];
      if (geogroupId) params.push({
        key: 'group_id',
        value: geogroupId
      });
      const {
        employee_home_work_distances_meter: distances
      } = await _http.HttpService.get('v1', `/partners/${partner.id}/employee_home_work_distances`, params, [], null, {
        partner: partner.code
      });
      return distances;
    } catch (err) {
      console.error('[PartnerService][getHomeWorkDistances]', err);
      throw err;
    }
  }
  static async searchCompanies(_ref29) {
    let {
      search,
      isSite
    } = _ref29;
    try {
      const params = [{
        key: 'search',
        value: search
      }];
      if (isSite) params.push({
        key: 'return_matching_etablissements',
        value: true
      });
      const {
        results
      } = await _http.HttpService.get('v1', `/companies`, params);
      if (isSite) {
        if (!results[0]) return [];
        const {
          nom_raison_sociale,
          matching_etablissements
        } = results[0];
        if (!matching_etablissements?.[0]) return [];
        const {
          etat_administratif,
          siret,
          adresse,
          latitude,
          longitude,
          libelle_commune
        } = matching_etablissements[0];
        if (etat_administratif === 'F') return [];
        return [{
          isSite: true,
          siret: siret || '',
          name: `${nom_raison_sociale || ''}${libelle_commune ? ` (${libelle_commune})` : ''}`,
          place: new _models.Place(siret || '', {
            type: 'Point',
            coordinates: [parseFloat(longitude), parseFloat(latitude)]
          }, {
            primaryText: adresse || '',
            city: libelle_commune
          })
        }];
      }
      await Promise.all(results.map(async result => {
        if (result.siege?.adresse && (!result.siege.latitude || !result.siege.longitude)) {
          const [place] = await _geocoder.GeocoderService.autocomplete(result.siege.adresse, {
            nbResults: 1
          });
          if (place) result.place = place;
        }
        return result;
      }));
      return results.reduce((res, _ref30) => {
        let {
          siren,
          etat_administratif,
          nom_raison_sociale,
          nombre_etablissements_ouverts,
          siege,
          place
        } = _ref30;
        if (siren && etat_administratif === 'A' && nom_raison_sociale && siege && nombre_etablissements_ouverts && nombre_etablissements_ouverts > 0) {
          const {
            latitude,
            longitude,
            numero_voie,
            type_voie,
            libelle_voie,
            code_postal,
            libelle_commune
          } = siege;
          if (!place && (!longitude || !latitude)) return res;
          res.push({
            siren,
            name: nom_raison_sociale || '',
            place: new _models.Place(siren, place?.point || {
              type: 'Point',
              coordinates: [parseFloat(longitude || ''), parseFloat(latitude || '')]
            }, {
              primaryText: [numero_voie, type_voie, libelle_voie].filter(Boolean).join(' '),
              secondaryText: [code_postal, libelle_commune].filter(Boolean).join(' ')
            }),
            sitesCount: nombre_etablissements_ouverts || 0
          });
        }
        return res;
      }, []);
    } catch (err) {
      console.error('[PartnerService][searchCompanies]', err);
      throw err;
    }
  }
  static async getCompanyEstablishment(_ref31) {
    let {
      siren,
      sitesCount
    } = _ref31;
    try {
      const nbPages = Math.ceil(sitesCount / 1000);
      const res = (await Promise.all(new Array(nbPages).fill(null).map((_, index) => _http.HttpService.get('v1', `/companies/${siren}/etablisements`, [{
        key: 'page_size',
        value: 1000
      }, {
        key: 'start_item',
        value: 1000 * index
      }])))).flatMap(_ref32 => {
        let {
          etablissements
        } = _ref32;
        return etablissements;
      });
      return res.reduce((res, _ref33) => {
        let {
          siret,
          adresseEtablissement,
          etablissementSiege,
          periodesEtablissement
        } = _ref33;
        if (siret && adresseEtablissement && periodesEtablissement?.find(_ref34 => {
          let {
            etatAdministratifEtablissement
          } = _ref34;
          return etatAdministratifEtablissement === 'A';
        })) {
          const {
            libelleCommuneEtablissement,
            numeroVoieEtablissement,
            typeVoieEtablissement,
            libelleVoieEtablissement,
            codePostalEtablissement,
            coordinates
          } = adresseEtablissement;
          if (coordinates) {
            const {
              latitude,
              longitude
            } = coordinates;
            res.push({
              siret,
              name: libelleCommuneEtablissement || siret,
              place: {
                coordinates: [longitude, latitude],
                primaryText: [numeroVoieEtablissement, typeVoieEtablissement, libelleVoieEtablissement].filter(Boolean).join(' '),
                secondaryText: [codePostalEtablissement, libelleCommuneEtablissement].filter(Boolean).join(' ')
              },
              isHeadquarters: Boolean(etablissementSiege)
            });
          }
        }
        return res;
      }, []);
    } catch (err) {
      console.error('[PartnerService][getCompanyEstablishment]', err);
      throw err;
    }
  }
  static async getCompaniesInArea(_ref35) {
    let {
      partner,
      period
    } = _ref35;
    try {
      const params = [{
        key: 'period',
        value: 'custom'
      }, {
        key: 'date_start',
        value: period.from.format('DD-MM-YYYY')
      }, {
        key: 'date_end',
        value: period.to.format('DD-MM-YYYY')
      }];
      const data = await _http.HttpService.get('v1', `/stats/partner/${partner.id}/companies_in_area`, params, [], null, {
        partner: partner.code
      });
      return data.map(_ref36 => {
        let {
          parent_title: parentTitle,
          photo,
          group_title: title,
          group_geo_point: geoPoint,
          group_total_members: membersCount,
          stats_counter_home_work_reference_trips_distance: homeWorkDistance,
          stats_counter_home_work_reference_trips_traces: homeWorkTracesCount,
          counter_active_user_with_home_work_trace: homeWorkActiveMembersCount
        } = _ref36;
        return {
          geoPoint,
          homeWorkActiveMembersCount,
          homeWorkDistance,
          homeWorkTracesCount,
          parentTitle,
          photo: photo ? `${_environment.defaultEnvironment.backendUrl}${photo}` : null,
          membersCount,
          title
        };
      });
    } catch (err) {
      console.error('[PartnerService][getCompaniesInArea]', err);
      throw err;
    }
  }
  static async getEmployeesStats(_ref37) {
    let {
      partner
    } = _ref37;
    try {
      const {
        count_converted_employees: convertedEmployeesCount,
        count_convertible_employees: convertibleEmployeesCount
      } = await _http.HttpService.get('v1', `/partners/${partner.id}/employee_stats`, [], [], null, {
        partner: partner.code
      });
      return {
        convertedEmployeesCount,
        convertibleEmployeesCount
      };
    } catch (err) {
      console.error('[PartnerService][getEmployeesStats]', err);
      throw err;
    }
  }
}
exports.PartnerService = PartnerService;
function parsePermission(key, extrapolated) {
  if (extrapolated) return 'extrapolated';
  if (key === 'Write') return 'write';
  if (key === 'Read') return 'read';
  return 'none';
}
function parsePartner(_ref38, isAdmin, isSupport) {
  let {
    is_admin_partner,
    is_default,
    partner: {
      id,
      area,
      cyclability_zone,
      code,
      title,
      icon,
      geo_group,
      bounds: _bounds,
      administrative_level,
      count_eco_counter,
      contact_email,
      created,
      whitelisted_domains,
      contracts,
      company_workforce_count,
      siren_number,
      has_stripe_client
    },
    permitted_pages
  } = _ref38;
  if (!code || !title) return null;
  let bounds;
  if (_bounds) {
    const [west, south, east, north] = _bounds;
    bounds = {
      north,
      east,
      south,
      west
    };
  } else {
    bounds = {
      north: 51.268318,
      east: 9.8678344,
      south: 41.2632185,
      west: -5.4534286
    };
  }
  let enableDashboardPageAccess;
  let dashboardTabsPermissions;
  if (permitted_pages) {
    enableDashboardPageAccess = {
      [_models.DashboardPages.Api]: permitted_pages.includes(_models.Permissions.API) || permitted_pages.includes(_models.Permissions.API_CREATE_API_KEY),
      [_models.DashboardPages.BicycleObservatory]: permitted_pages.includes(_models.Permissions.ROAD_USE) || permitted_pages.includes(_models.Permissions.ROAD_USE_EXTRAPOLATED) || permitted_pages.includes(_models.Permissions.BICYCLE_TRAFFIC_FLUIDITY) || permitted_pages.includes(_models.Permissions.ORIGIN_DESTINATION) || permitted_pages.includes(_models.Permissions.GEOVELO_ACTIVITY) || permitted_pages.includes(_models.Permissions.SUDDEN_BRAKINGS) || permitted_pages.includes(_models.Permissions.TRAVEL_SIMULATION),
      [_models.DashboardPages.CartographicData]: permitted_pages.includes(_models.Permissions.ACCIDENTALITY) || permitted_pages.includes(_models.Permissions.BICYCLE_FACILITIES) || permitted_pages.includes(_models.Permissions.NETWORK_CYCLABILITY) || permitted_pages.includes(_models.Permissions.REPORTS) || permitted_pages.includes(_models.Permissions.ISOCHRONES) || permitted_pages.includes(_models.Permissions.DISCONTINUITY) || permitted_pages.includes(_models.Permissions.ROADS_QUALITY),
      [_models.DashboardPages.Downloads]: permitted_pages.includes(_models.Permissions.DOWNLOADS),
      [_models.DashboardPages.GIS]: permitted_pages.includes(_models.Permissions.SIG_FILES),
      [_models.DashboardPages.Help]: true,
      [_models.DashboardPages.Home]: geo_group && (permitted_pages.includes(_models.Permissions.HOMEPAGE) || permitted_pages.includes(_models.Permissions.HOMEPAGE_EXTRAPOLATED) || permitted_pages.includes(_models.Permissions.HOMEPAGE_ESSENTIELLE)) || false,
      [_models.DashboardPages.Parkings]: permitted_pages.includes(_models.Permissions.PARKINGS),
      [_models.DashboardPages.Settings]: true,
      [_models.DashboardPages.Promotion]: permitted_pages.includes(_models.Permissions.ANIMATION) || permitted_pages.includes(_models.Permissions.WIDGETS) || permitted_pages.includes(_models.Permissions.RIDES),
      [_models.DashboardPages.QA]: (code === 'geovelo' || code === 'maintenance') && (permitted_pages.includes(_models.Permissions.OSM_REPORTS) || permitted_pages.includes(_models.Permissions.ITINERARY_REPORTS) || permitted_pages.includes(_models.Permissions.REFERENCE_ROUTES) || permitted_pages.includes(_models.Permissions.EXCLUSION_AREAS) || permitted_pages.includes(_models.Permissions.USERS_LOGS) || permitted_pages.includes(_models.Permissions.BIKE_ROUTES) || isSupport) || false
    };
    const matches = [{
      tab: 'apiAvailability',
      backendPermission: permitted_pages.includes(_models.Permissions.API_CREATE_API_KEY) ? 'Write' : permitted_pages.includes(_models.Permissions.API) ? 'Read' : 'None'
    }, {
      tab: 'apiDoc',
      backendPermission: permitted_pages.includes(_models.Permissions.API_CREATE_API_KEY) ? 'Write' : permitted_pages.includes(_models.Permissions.API) ? 'Read' : 'None'
    }, {
      tab: 'apiKeys',
      backendPermission: permitted_pages.includes(_models.Permissions.API_CREATE_API_KEY) || isAdmin ? 'Write' : permitted_pages.includes(_models.Permissions.API) ? 'Read' : 'None'
    }, {
      tab: 'animation',
      backendPermission: permitted_pages.includes(_models.Permissions.ANIMATION) ? 'Write' : 'None'
    }, {
      tab: 'communityChallenges',
      backendPermission: permitted_pages.includes(_models.Permissions.ANIMATION) ? 'Write' : 'None'
    }, {
      tab: 'communityChallengesForm',
      backendPermission: permitted_pages.includes(_models.Permissions.ANIMATION) ? 'Write' : 'None'
    }, {
      tab: 'communityMembers',
      backendPermission: permitted_pages.includes(_models.Permissions.ANIMATION) ? 'Write' : 'None'
    }, {
      tab: 'communityNews',
      backendPermission: permitted_pages.includes(_models.Permissions.ANIMATION) ? 'Write' : 'None'
    }, {
      tab: 'communityNewsForm',
      backendPermission: permitted_pages.includes(_models.Permissions.ANIMATION) ? 'Write' : 'None'
    }, {
      tab: 'communityHomeWorkJourneys',
      backendPermission: permitted_pages.includes(_models.Permissions.ANIMATION) ? 'Write' : 'None'
    }, {
      tab: 'counterForm',
      backendPermission: permitted_pages.includes(_models.Permissions.ROAD_USE) || permitted_pages.includes(_models.Permissions.ROAD_USE_EXTRAPOLATED) ? 'Write' : 'None',
      extrapolated: permitted_pages.includes(_models.Permissions.ROAD_USE_EXTRAPOLATED)
    }, {
      tab: 'cyclability',
      backendPermission: permitted_pages.includes(_models.Permissions.NETWORK_CYCLABILITY) ? 'Write' : 'None'
    }, {
      tab: 'downloads',
      backendPermission: permitted_pages.includes(_models.Permissions.DOWNLOADS) ? 'Write' : 'None'
    }, {
      tab: 'facilities',
      backendPermission: permitted_pages.includes(_models.Permissions.BICYCLE_FACILITIES) ? 'Write' : 'None'
    }, {
      tab: 'facilitiesReports',
      backendPermission: permitted_pages.includes(_models.Permissions.BICYCLE_FACILITIES) ? 'Write' : 'None'
    }, {
      tab: 'facilitiesStats',
      backendPermission: permitted_pages.includes(_models.Permissions.BICYCLE_FACILITIES) ? 'Write' : 'None'
    }, {
      tab: 'newReport',
      backendPermission: permitted_pages.includes(_models.Permissions.REPORTS) || permitted_pages.includes(_models.Permissions.OSM_REPORTS) ? 'Write' : 'None'
    }, {
      tab: 'facilitiesIsochrones',
      backendPermission: permitted_pages.includes(_models.Permissions.ISOCHRONES) ? 'Write' : 'None'
    }, {
      tab: 'facilitiesGISFiles',
      backendPermission: permitted_pages.includes(_models.Permissions.SIG_FILES) ? 'Write' : 'None'
    }, {
      tab: 'facilitiesRoadsQuality',
      backendPermission: permitted_pages.includes(_models.Permissions.ROADS_QUALITY) ? 'Write' : 'None'
    }, {
      tab: 'facilitiesRoadsQualityAnalysis',
      backendPermission: permitted_pages.includes(_models.Permissions.ROADS_QUALITY) ? 'Write' : 'None'
    }, {
      tab: 'home',
      backendPermission: permitted_pages.includes(_models.Permissions.HOMEPAGE) || permitted_pages.includes(_models.Permissions.HOMEPAGE_ESSENTIELLE) || permitted_pages.includes(_models.Permissions.HOMEPAGE_EXTRAPOLATED) ? 'Write' : 'None',
      extrapolated: permitted_pages.includes(_models.Permissions.HOMEPAGE_EXTRAPOLATED)
    }, {
      tab: 'itineraryReports',
      backendPermission: permitted_pages.includes(_models.Permissions.ITINERARY_REPORTS) ? 'Write' : 'None'
    }, {
      tab: 'manageContracts',
      backendPermission: permitted_pages.includes(_models.Permissions.CONTRACTS_MANAGEMENT) ? 'Write' : 'None'
    }, {
      tab: 'manageUsers',
      backendPermission: permitted_pages.includes(_models.Permissions.ACCESS_MANAGEMENT) ? 'Write' : 'None'
    }, {
      tab: 'osmReports',
      backendPermission: permitted_pages.includes(_models.Permissions.OSM_REPORTS) ? 'Write' : 'None'
    }, {
      tab: 'qaRefRoutes',
      backendPermission: permitted_pages.includes(_models.Permissions.REFERENCE_ROUTES) ? 'Write' : 'None',
      partnerCode: 'geovelo'
    }, {
      tab: 'qaUsersLogs',
      backendPermission: permitted_pages.includes(_models.Permissions.USERS_LOGS) ? 'Write' : 'None',
      partnerCode: 'geovelo'
    }, {
      tab: 'exclusionZones',
      backendPermission: permitted_pages.includes(_models.Permissions.EXCLUSION_AREAS) ? 'Write' : 'None',
      partnerCode: 'geovelo'
    }, {
      tab: 'exclusionZonesForm',
      backendPermission: permitted_pages.includes(_models.Permissions.EXCLUSION_AREAS) ? 'Write' : 'None',
      partnerCode: 'geovelo'
    }, {
      tab: 'facilitiesSuggestions',
      backendPermission: permitted_pages.includes(_models.Permissions.FACILITIES_SUGGESTIONS) ? 'Write' : 'None'
    }, {
      tab: 'facilitiesSuggestionsForm',
      backendPermission: permitted_pages.includes(_models.Permissions.FACILITIES_SUGGESTIONS) ? 'Write' : 'None'
    }, {
      tab: 'discontinuity',
      backendPermission: permitted_pages.includes(_models.Permissions.DISCONTINUITY) ? 'Write' : 'None'
    }, {
      tab: 'discontinuityAnalysis',
      backendPermission: permitted_pages.includes(_models.Permissions.DISCONTINUITY) ? 'Write' : 'None'
    }, {
      tab: 'reports',
      backendPermission: permitted_pages.includes(_models.Permissions.REPORTS) ? 'Write' : 'None'
    }, {
      tab: 'potholeReports',
      backendPermission: permitted_pages.includes(_models.Permissions.ROADS_QUALITY) ? 'Write' : 'None'
    }, {
      tab: 'tourismBikeRoutes',
      backendPermission: permitted_pages.includes(_models.Permissions.BIKE_ROUTES) ? 'Write' : 'None',
      partnerCode: 'geovelo'
    }, {
      tab: 'tourismRides',
      backendPermission: permitted_pages.includes(_models.Permissions.RIDES) ? 'Write' : 'None'
    }, {
      tab: 'parkings',
      backendPermission: permitted_pages.includes(_models.Permissions.PARKINGS) ? 'Write' : 'None'
    }, {
      tab: 'parkingsStats',
      backendPermission: permitted_pages.includes(_models.Permissions.PARKINGS) ? 'Write' : 'None'
    }, {
      tab: 'parkingsReports',
      backendPermission: permitted_pages.includes(_models.Permissions.PARKINGS) ? 'Write' : 'None'
    }, {
      tab: 'usageAccidentology',
      backendPermission: permitted_pages.includes(_models.Permissions.ACCIDENTALITY) || permitted_pages.includes(_models.Permissions.ACCIDENTALITY_EXTRAPOLATED) ? 'Write' : 'None',
      extrapolated: permitted_pages.includes(_models.Permissions.ACCIDENTALITY_EXTRAPOLATED)
    }, {
      tab: 'usageAccidentologyAccidentZones',
      backendPermission: permitted_pages.includes(_models.Permissions.ACCIDENTALITY) || permitted_pages.includes(_models.Permissions.ACCIDENTALITY_EXTRAPOLATED) ? 'Write' : 'None',
      extrapolated: permitted_pages.includes(_models.Permissions.ACCIDENTALITY_EXTRAPOLATED)
    }, {
      tab: 'usageActivityStatistics',
      backendPermission: permitted_pages.includes(_models.Permissions.GEOVELO_ACTIVITY) ? 'Write' : 'None'
    }, {
      tab: 'usageFluidity',
      backendPermission: permitted_pages.includes(_models.Permissions.BICYCLE_TRAFFIC_FLUIDITY) ? 'Write' : 'None'
    }, {
      tab: 'usageFluidityAnalysis',
      backendPermission: permitted_pages.includes(_models.Permissions.BICYCLE_TRAFFIC_FLUIDITY) ? 'Write' : 'None'
    }, {
      tab: 'usageOriginDestination',
      backendPermission: permitted_pages.includes(_models.Permissions.ORIGIN_DESTINATION) ? 'Write' : 'None'
    }, {
      tab: 'usageOriginDestinationOld',
      backendPermission: permitted_pages.includes(_models.Permissions.ORIGIN_DESTINATION) ? 'Write' : 'None'
    }, {
      tab: 'usageOriginDestinationAnalysis',
      backendPermission: permitted_pages.includes(_models.Permissions.ORIGIN_DESTINATION) ? 'Write' : 'None'
    }, {
      tab: 'usageOriginDestinationList',
      backendPermission: permitted_pages.includes(_models.Permissions.ORIGIN_DESTINATION) ? 'Write' : 'None'
    }, {
      tab: 'usagePointAttendance',
      backendPermission: permitted_pages.includes(_models.Permissions.ROAD_USE) || permitted_pages.includes(_models.Permissions.ROAD_USE_EXTRAPOLATED) ? 'Write' : 'None',
      extrapolated: permitted_pages.includes(_models.Permissions.ROAD_USE_EXTRAPOLATED)
    }, {
      tab: 'usageRoadsUse',
      backendPermission: permitted_pages.includes(_models.Permissions.ROAD_USE) || permitted_pages.includes(_models.Permissions.ROAD_USE_EXTRAPOLATED) ? 'Write' : 'None',
      extrapolated: permitted_pages.includes(_models.Permissions.ROAD_USE_EXTRAPOLATED)
    }, {
      tab: 'extrapolation',
      backendPermission: permitted_pages.includes(_models.Permissions.ROAD_USE) || permitted_pages.includes(_models.Permissions.ROAD_USE_EXTRAPOLATED) ? 'Write' : 'None'
    }, {
      tab: 'usageRoadsUseAnalysis',
      backendPermission: permitted_pages.includes(_models.Permissions.ROAD_USE) || permitted_pages.includes(_models.Permissions.ROAD_USE_EXTRAPOLATED) ? 'Write' : 'None',
      extrapolated: permitted_pages.includes(_models.Permissions.ROAD_USE_EXTRAPOLATED)
    }, {
      tab: 'usageTravelSimulations',
      backendPermission: permitted_pages.includes(_models.Permissions.TRAVEL_SIMULATION) ? 'Write' : 'None',
      partnerCode: 'lyon'
    }, {
      tab: 'widgets',
      backendPermission: permitted_pages.includes(_models.Permissions.WIDGETS) ? 'Write' : 'None'
    }];
    dashboardTabsPermissions = matches.reduce((res, _ref39) => {
      let {
        tab,
        backendPermission,
        partnerCode,
        extrapolated
      } = _ref39;
      res[tab] = partnerCode && partnerCode !== code || _models.dashboardTabsStatus[tab] === 'alpha' && !isAdmin || _models.dashboardTabsStatus[tab] === 'support' && !isSupport ? 'none' : parsePermission(backendPermission, extrapolated);
      return res;
    }, {
      animation: 'none',
      apiAvailability: 'none',
      apiDoc: 'none',
      apiKeys: 'none',
      communityChallenges: 'none',
      communityChallengesForm: 'none',
      communityHomeWorkJourneys: 'none',
      communityMembers: 'none',
      communityNews: 'none',
      communityNewsForm: 'none',
      counterForm: 'none',
      cyclability: 'none',
      downloads: 'none',
      facilities: 'none',
      facilitiesReports: 'none',
      facilitiesStats: 'none',
      facilitiesIsochrones: 'none',
      facilitiesGISFiles: 'none',
      facilitiesRoadsQuality: 'none',
      facilitiesRoadsQualityAnalysis: 'none',
      newReport: 'none',
      home: 'none',
      itineraryReports: 'none',
      manageContracts: 'none',
      manageUsers: 'none',
      osmReports: 'none',
      parkings: 'none',
      parkingsStats: 'none',
      parkingsReports: 'none',
      qaRefRoutes: 'none',
      qaUsersLogs: 'none',
      exclusionZones: 'none',
      exclusionZonesForm: 'none',
      facilitiesSuggestions: 'none',
      facilitiesSuggestionsForm: 'none',
      reports: 'none',
      potholeReports: 'none',
      tourismBikeRoutes: 'none',
      tourismRides: 'none',
      usageAccidentology: 'none',
      usageAccidentologyAccidentZones: 'none',
      usageActivityStatistics: 'none',
      usageFluidity: 'none',
      usageFluidityAnalysis: 'none',
      usageOriginDestinationOld: 'none',
      usageOriginDestination: 'none',
      usageOriginDestinationAnalysis: 'none',
      usageOriginDestinationList: 'none',
      usagePointAttendance: 'none',
      usageRoadsUse: 'none',
      usageRoadsUseAnalysis: 'none',
      usageTravelSimulations: 'none',
      widgets: 'none',
      extrapolation: 'none',
      discontinuity: 'none',
      discontinuityAnalysis: 'none'
    });
  }
  return new _models.Partner(id, area || null, cyclability_zone || null, code, title, icon ? icon.indexOf('http') === -1 ? `${_environment.defaultEnvironment.backendUrl}${icon}` : `${icon}` : null, geo_group, bounds || null, _cyclabilityZone.administrativeLevels[administrative_level || 'EPCI'] || (code === 'geovelo' ? 'country' : code === 'normandie' ? 'region' : 'epci'), !!is_default, enableDashboardPageAccess || {
    [_models.DashboardPages.Api]: false,
    [_models.DashboardPages.BicycleObservatory]: false,
    [_models.DashboardPages.CartographicData]: false,
    [_models.DashboardPages.Downloads]: true,
    [_models.DashboardPages.GIS]: true,
    [_models.DashboardPages.Help]: true,
    [_models.DashboardPages.Home]: false,
    [_models.DashboardPages.Parkings]: false,
    [_models.DashboardPages.Settings]: true,
    [_models.DashboardPages.Promotion]: false,
    [_models.DashboardPages.QA]: false
  }, dashboardTabsPermissions || {}, Boolean(count_eco_counter && count_eco_counter > 0), contact_email || null, is_admin_partner, created ? (0, _moment.default)(created) : undefined, whitelisted_domains, contracts?.map(_ref40 => {
    let {
      id,
      contract_template,
      contract_template_code,
      start_datetime,
      end_datetime,
      company_workforce_count_limit
    } = _ref40;
    return {
      id,
      contractTemplate: {
        code: contract_template_code || (typeof contract_template === 'object' ? contract_template.code : '')
      },
      contractTemplateId: typeof contract_template === 'number' ? contract_template : undefined,
      startDateTime: start_datetime ? (0, _moment.default)(start_datetime) : null,
      endDateTime: end_datetime ? (0, _moment.default)(end_datetime) : null,
      companyMaxSize: company_workforce_count_limit || null
    };
  }), company_workforce_count || null, siren_number || null, has_stripe_client || false);
}
function parsePartnerLayer(_ref41) {
  let {
    id,
    name,
    geojson_content
  } = _ref41;
  const collection = JSON.parse(geojson_content);
  let LegendIcon;
  if (_http.HttpService.partner === 'larochelle') {
    collection.features.forEach(_ref42 => {
      let {
        properties
      } = _ref42;
      properties.id = id;
      properties.color = '#2f81c2';
      properties.width = 3;
      LegendIcon = _components.LaRochelleFutureFacilitiesLegendIcon;
    });
  } else if (_http.HttpService.partner === 'lav') {
    collection.features.forEach(_ref43 => {
      let {
        properties
      } = _ref43;
      properties.id = id;
      properties.width = 5;
      if (properties.amenag) {
        properties.color = '#659F4A';
        LegendIcon = _components.LAVDedicatedRoadsLegendIcon;
      } else if (properties.shuttle) {
        properties.color = '#e6701a';
        properties.dashed = true;
        LegendIcon = _components.LAVShuttlesLegendIcon;
      } else {
        properties.color = '#e6701a';
        LegendIcon = _components.LAVSharedRoadsLegendIcon;
      }
    });
  }
  return {
    id,
    name,
    collection,
    LegendIcon
  };
}
function parsePartnerUser(_ref44) {
  let {
    user: {
      id,
      created,
      username,
      email,
      last_activity
    },
    is_admin_partner,
    permissions
  } = _ref44;
  return new _models.PartnerUser(id, (0, _moment.default)(created), email, username, (0, _moment.default)(last_activity), is_admin_partner, permissions);
}
function parsePartnerContractTemplate(_ref45) {
  let {
    id,
    code,
    title,
    api_usage_limit,
    permitted_page_codes
  } = _ref45;
  return new _models.PartnerContractTemplate(id, code, title, api_usage_limit, permitted_page_codes);
}
function parsePartnerContract(_ref46) {
  let {
    id,
    title,
    start_datetime,
    end_datetime,
    api_usage_limit,
    contract_template,
    company_workforce_count_limit
  } = _ref46;
  return new _models.PartnerContract(id, title, (0, _moment.default)(start_datetime), end_datetime ? (0, _moment.default)(end_datetime).set({
    hour: 23,
    minute: 59,
    second: 59
  }) : null, api_usage_limit, typeof contract_template === 'number' ? contract_template : -1, company_workforce_count_limit || null);
}
function parsePartnerFlowsStats(_ref47) {
  let {
    mean_stop_time_by_kilometer: meanStopTimeByKilometer,
    distance_percent_on_greenway,
    distance_percent_on_cycleway,
    distance_percent_on_lane,
    distance_percent_on_sharebusway,
    distance_percent_on_opposite,
    distance_percent_on_mixedfacilities,
    distance_percent_on_no_facility
  } = _ref47;
  return {
    meanStopTimeByKilometer,
    roadsUsedPercentages: {
      safe: distance_percent_on_cycleway + distance_percent_on_greenway,
      withoutFacilities: distance_percent_on_no_facility,
      others: distance_percent_on_lane + distance_percent_on_sharebusway + distance_percent_on_opposite + distance_percent_on_mixedfacilities
    },
    facilitiesUsedPercentages: {
      greenways: distance_percent_on_greenway,
      cycleways: distance_percent_on_cycleway,
      lanes: distance_percent_on_lane,
      mixedfacilities: distance_percent_on_mixedfacilities,
      opposites: distance_percent_on_opposite,
      sharedBusways: distance_percent_on_sharebusway
    }
  };
}
const certificateInvalidationReasons = exports.certificateInvalidationReasons = ['journeysOnWorkingFromHomeDay', 'journeysOnNotWorkingDay', 'other'];
const backendCertificateInvalidationReasonsMap = {
  journeysOnNotWorkingDay: 'ATTESTATION_INVALID_JOURNEYS_ON_NOT_WORKING_DAY',
  journeysOnWorkingFromHomeDay: 'ATTESTATION_INVALID_JOURNEYS_ON_WORKING_FROM_HOME_DAY',
  other: 'ATTESTATION_INVALID_OTHER'
};